import React from 'react'
import { IconEdit, IconX } from '@tabler/icons-react'

interface IToggleEditButton {
    isEditing: boolean
    setIsEditing: (editMode: boolean) => void
}

const ToggleEditButton = ({ isEditing, setIsEditing }: IToggleEditButton) => {
    return (
        <button className="font-light flex items-end gap-2" onClick={() => setIsEditing(!isEditing)}>
            {isEditing ? <IconX size={28} stroke={1} /> : <IconEdit size={28} stroke={1} />}
        </button>
    )
}

export default ToggleEditButton
