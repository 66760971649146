import { Control, FieldValues } from 'react-hook-form'
import { CustomSelect, InputForm } from '@/shared/index'
import { IField } from '@/utils/formFields' 
import { Path } from 'react-hook-form'

interface IFieldProps<TFieldValues extends FieldValues = FieldValues> {
	field: IField
	isEditMode?: boolean
	control: Control<TFieldValues>
}

export const Field = <TFieldValues extends FieldValues>({ field, isEditMode = true, control }: IFieldProps<TFieldValues>) => {
    switch (field.type) {
        case 'input':
            return (
                <InputForm
                    control={control}
                    name={field.name as Path<TFieldValues>}
                    label={field.label}
                    type={field.inputType}
                    disabled={!isEditMode}
                    step={field.step ?? '1'}
                />
            )
        case 'select':
            return (
                <CustomSelect
                    control={control}
                    name={field.name as Path<TFieldValues>}
                    label={field.label}
                    options={field.options}
                    isDisabled={!isEditMode}
                    defaultValue={field.defaultValue ?? ''}
                />
            )
        default:
            return null
    }
}
