import React from 'react'
import Image from 'next/image'
import Loader from '../Loader/Loader'
import logo from '../../../public/assets/uma.png'

export function FullLoader(): JSX.Element {
    return (
        <div className="w-screen h-screen bg-white fixed top-0 left-0 flex flex-col justify-center items-center z-50">
            <div className="flex flex-col items-center relative w-[200px] h-[200px]">
                <div className="absolute -right-4 -top-4">
                    <Loader size="sm" />
                </div>
                <Image src={logo} alt="UMA" width={200} priority={true} />
            </div>
        </div>
    )
}

export default FullLoader
