import React from 'react'
import { Controller, Control, RegisterOptions, FieldValues, Path } from 'react-hook-form'
import Select from 'react-select'
import { customStyles } from './selectStyles'

type OptionType = {
    label: string
    value: string | number
}

interface CustomSelectProps<TFieldValues extends FieldValues> {
    label: string
    name: Path<TFieldValues>
    control: Control<TFieldValues>
    options: OptionType[]
    rules?: Omit<RegisterOptions<TFieldValues>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
    defaultValue?: TFieldValues[Path<TFieldValues>] | undefined
    placeholder?: string
    isLoading?: boolean
    isDisabled?: boolean
    noOptionsMessage?: string
    onChange?: (value: OptionType | null) => void
}

export default function CustomSelect<TFieldValues extends FieldValues>({
    label,
    name,
    control,
    options,
    rules,
    defaultValue,
    placeholder = 'Selecciona una opción',
    isLoading,
    isDisabled,
    noOptionsMessage,
    onChange,
}: CustomSelectProps<TFieldValues>) {
    return (
        <div className="flex flex-col gap-1">
            {label && (
                <label htmlFor={name} className="font-light text-xs text-black/70">
                    {label}
                </label>
            )}

            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                rules={rules}
                render={({ field: { onChange: onHookFormChange, onBlur, value, ref }, fieldState }) => (
                    <>
                        <div className="max-h-[50px]">
                            <Select
                                ref={ref}
                                options={options}
                                value={options.find(c => c.value === value)}
                                onChange={val => {
                                    if (onChange) {
                                        onChange(val)
                                    }
                                    onHookFormChange(val ? val.value : null)
                                }}
                                onBlur={onBlur}
                                styles={customStyles}
                                placeholder={placeholder}
                                isLoading={isLoading}
                                isDisabled={isDisabled}
                                noOptionsMessage={() => noOptionsMessage && noOptionsMessage}
                                isClearable
                            />
                        </div>
                        {fieldState.error && <p className="text-red-500 text-sm">{fieldState.error.message}</p>}
                    </>
                )}
            />
        </div>
    )
}
