import { IconEye, IconEyeClosed } from "@tabler/icons-react";
import { Input } from "@umahealth/occipital/client";
import React, { ReactNode, useState } from "react";
import { Controller, Control, FieldValues, Path } from "react-hook-form";

interface InputProps<TFieldValues extends FieldValues> {
	name: Path<TFieldValues>;
	label?: string;
	customLabel?: ReactNode;
	type?: string;
	placeholder?: string;
	className?: string;
	control: Control<TFieldValues>;
	defaultValue?: string;
	rules?: object;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	disabled?: boolean;
	step?: string;
	autoComplete?: string;
}

export default function InputForm<TFieldValues extends FieldValues>({
	control,
	name,
	label,
	customLabel,
	type = "text",
	rules,
	placeholder,
	defaultValue = "",
	onChange,
	disabled,
	step,
	autoComplete = "off",
	...rest
}: InputProps<TFieldValues>) {
	const [showPasswordStatus, setShowPasswordStatus] =
		useState<boolean>(false);

	return (
		<div className="flex flex-col gap-1">
			{customLabel && (
				<div className="font-light text-xs text-black/70">
					{customLabel}
				</div>
			)}
			<Controller
				name={name}
				control={control}
				rules={rules}
				disabled={disabled}
				render={({ field, fieldState: { error } }) => (
					<div className="relative">
						<Input
							label={label}
							type={
								type === "password"
									? showPasswordStatus
										? "text"
										: "password"
									: type
							}
							step={step}
							autoComplete={autoComplete}
							{...field}
							{...rest}
							placeholder={placeholder || ""}
							className={`${type === "password" ? "pr-10" : ""}`}
							onChange={(e) => {
								field.onChange(e);
								if (onChange) onChange(e);
							}}
						/>
						{error && (
							<p className="text-red-500 text-sm mt-1">
								{error.message}
							</p>
						)}
					</div>
				)}
			/>
		</div>
	);
}
