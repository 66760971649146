export const customStyles = {
    control: (provided: any, state: { isDisabled: any }) => ({
        ...provided,
        width: '100%',
        maxHeight: '50px',
        backgroundColor: '#fff',
        borderColor: '#c1c1c1',
        borderRadius: '0px',
        fontWeight: '500',
		opacity: state.isDisabled ? 0.5 : 1
    }),
    container: (provided: any) => ({
        ...provided,
        width: '100%',
    }),
    option: (provided: any, state: { isFocused: any }) => ({
        ...provided,
        color: '#000',
        backgroundColor: state.isFocused ? '#e5e7eb' : null,
    }),
    valueContainer: (provided: any) => ({
        ...provided,
        height: '50px',
        display: 'flex',
        alignItems: 'center',
        padding: '0 8px',
    }),
    input: (provided: any) => ({
        ...provided,
        margin: '0px',
        padding: '0px',
        height: 'inherit',
    }),
}