import { Company, TAppModuleId, TAppHtmlElementKey } from "@/modules/company/domain/ICompany";

/**
 * Checks if a company has access to a specified module.
 * 
 * @param {Company} company - The company to check access for.
 * @param {TAppModuleId} appModuleId - The ID of the application module to check access for.
 * @returns {boolean} True if the company has access to the module, false otherwise.
 */
export function companyCanAccessModule(company: Company, appModuleId: TAppModuleId): boolean {
  return Boolean(company.modules.find((c) => c.app_module.id === appModuleId)?.active);
}

/**
 * Checks if a company can see a specified HTML element or component.
 * 
 * @param {Company} company - The company to check visibility for.
 * @param {TAppHtmlElementKey} appElementKey - The key of the HTML element to check visibility for.
 * @returns {boolean} True if the company can see the element, false otherwise.
 */
export function companyCanSeeHtmlElement(company: Company, appElementKey: TAppHtmlElementKey): boolean {
  const element = company.visible_elements.find((ve) => ve.key === appElementKey);
  if (!element?.module) return Boolean(element);

  return Boolean(element && companyCanAccessModule(company, element.module.app_module.id));
}
