/**
 * app module keys.
 */
type AppModuleKey =
	| "OPERACIONES"
	| "INGRESO_MEDICOS"
	| "USUARIOS"
	| "HOME"
	| "HISTORIAL"
	| "PERFIL"
	| "BASES";
/**
 * Mapping of application module names to their IDs.
 */
export const appModules: Record<AppModuleKey, number> = {
	OPERACIONES: 1,
	INGRESO_MEDICOS: 2,
	USUARIOS: 3,
	HOME: 4,
	HISTORIAL: 5,
	PERFIL: 6,
	BASES: 7,
};

/**
 * Type representing the application module ID.
 */
export type TAppModuleId = typeof appModules[keyof typeof appModules];

/**
 * Mapping of application HTML elements keys.
 */
export const appHTMLElementsKey: Record<string, string> = {};

/**
 * Type representing the application HTML element key.
 */
export type TAppHtmlElementKey = typeof appHTMLElementsKey[keyof typeof appHTMLElementsKey];

/**
 * Represents an application module.
 * @property {TAppModuleId} id - The ID of the application module.
 * @property {string} name - The name of the application module.
 */
type AppModule = {
  id: TAppModuleId,
  name: string
};

/**
 * Represents a company module.
 * @property {AppModule} app_module - The application module.
 * @property {boolean} active - Indicates if the module is active.
 */
type CompanyModule = {
  app_module: AppModule;
  active: boolean;
};

/**
 * Represents an application element parameter.
 * @property {string} key - The key of the element parameter.
 * @property {string | null} label - The label of the element parameter.
 * @property {CompanyModule | null} module - The associated company module.
 */
type AppElementParam = {
  key: string,
  label: string | null,
  module: CompanyModule | null
};

/**
 * Represents an operation type.
 * @property {number} id - The ID of the operation type.
 * @property {string} name - The name of the operation type.
 */
export type OperationType = {
  id: number;
  name: string;
};


/**
 * Represents a company.
 * @property {number} id - The ID of the company.
 * @property {string} logo - The logo of the company.
 * @property {string} name - The name of the company.
 * @property {CompanyModule[]} modules - The list of company modules.
 * @property {AppElementParam[]} visible_elements - The list of visible elements parameters.
 */
export type Company = {
  id: number;
  logo: string;
  name: string;
  modules: CompanyModule[];
  visible_elements: AppElementParam[];
  operation_type: OperationType;
};
