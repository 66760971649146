import React  from 'react'
import { Company, appModules } from '@/modules/company/domain/ICompany'
import { companyCanAccessModule } from '@/utils/company'
import { Icon, IIcon, SidebarContainer, SidebarElement } from '@umahealth/occipital/client'
import { cn } from '@/utils/cn'
import { removeAuthCookies } from '@/modules/users/service/auth'
import { useRouter } from 'next/navigation'

type HeaderProps = {
    logged: boolean, 
    currentCompany: Company
}

// Define a type for the link objects
type LinkItem = {
	appModuleId: number;
	href: string;
	text: string;
	isExternal?: boolean;
	/** icon of the element */
	icon: IIcon["name"];
};

/* header-usuarios-link*/
const links: LinkItem[] = [
	{
		appModuleId: appModules.HOME,
		href: "/",
		text: "Home",
		icon: "bigHome",
	},
	{
		appModuleId: appModules.OPERACIONES,
		href: "/operaciones",
		text: "Evaluación",
		icon: "file",
	},
	{
		appModuleId: appModules.OPERACIONES,
		href: "/bases",
		text: "Bases",
		icon: "train",
	},
	{
		appModuleId: appModules.HISTORIAL,
		href: "/history",
		text: "Historial",
		icon: "history",
	},
	{
		appModuleId: appModules.USUARIOS,
		href: "/usuarios",
		text: "Usuarios",
		icon: "userPlus",
	},
	{
		appModuleId: appModules.INGRESO_MEDICOS,
		href: process.env.NEXT_PUBLIC_ONLINEDOCTOR_URL!,
		text: "Ingreso Portal Médicos",
		icon: "hospital",
		isExternal: true,
	},
];

export default function Header({ logged, currentCompany }:HeaderProps ) {
	const router = useRouter()

    const handleSignOut = async () => {
        await removeAuthCookies()
		router.push('/usuarios/acceso')
    }

    if (!logged || !currentCompany) {
        return null
    }


    return (
		<SidebarContainer
		logoVisibility='hideOnClose'
		arrowPosition='right'
			src={{
				src: currentCompany.logo,
				height: 56*4,
				width: 132*4,
			}}
			data-cy={`header-container`}
		>
			{links.map(
				(link) =>
					companyCanAccessModule(
						currentCompany,
						link.appModuleId
					) && (
						<SidebarElement
							key={link.text}
							name={link.icon}
							href={link.href}
							data-cy={`header-${link.text}-link`}
						>
							{link.text}
						</SidebarElement>
					)
			)}
			<li className="w-[149px]  h-14 mt-auto mb-6">
				<button
					className={cn(
						"flex align-middle content-center group-[.isClosed]:justify-center justify-start items-center py-4 px-4 hover:bg-primary-25 rounded-2xl"
					)}
					onClick={() => {
						handleSignOut();
					}}
				>
					<Icon
						size="size-6"
						className="mr-2"
						color="text-grey-500"
						name="logout"
					/>
					<span className="group-[.isClosed]:sr-only">
						Cerrar
					</span>
				</button>
			</li>
		</SidebarContainer>
	);
}
