import React from 'react'
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { Table } from '@tanstack/react-table';

interface IPagination<TData> {
	table: Table<TData>
	isLoading: boolean
}

const Pagination = <TData,>({ table, isLoading }: IPagination<TData>) => {
  return (
		<div className="w-[calc(100%-160px)] flex flex-col justify-center fixed bottom-8 rounded-2xl bg-white/80 py-4 mt-12">
			<div className="flex justify-center items-center">
				<button
					className="flex items-center justify-center rounded-lg mx-2 p-1 w-10 h-10 bg-primary text-white disabled:opacity-70"
					onClick={() => table.previousPage()}
					disabled={!table.getCanPreviousPage() || isLoading}
				>
					<IconChevronLeft size={24} />
				</button>
				{table.getPageCount() > 0 && (
					<div className="mx-3">
						Página{" "}
						<span className="font-bold">
							{table.getState().pagination.pageIndex + 1}
						</span>{" "}
						de{" "}
						<span className="font-bold">
							{table.getPageCount()}
						</span>
					</div>
				)}
				<button
					className="flex items-center justify-center rounded-lg mx-2 p-1 w-10 h-10 bg-primary text-white disabled:opacity-70"
					onClick={() => table.nextPage()}
					disabled={!table.getCanNextPage() || isLoading}
				>
					<IconChevronRight size={24} />
				</button>
			</div>
		</div>
  );
}

export default Pagination