import React from 'react'

interface ILoader {
    size?: 'sm' | 'md' | 'lg'
}

const Loader = ({ size = 'md' }: ILoader) => {
    const loaderSizes = {
        sm: 'w-8 h-8',
        md: 'w-12 h-12',
        lg: 'w-16 h-16',
    }

    return <div className={`relative ${loaderSizes[size]} border-2 border-primary border-t-white rounded-full animate-rotate`}></div>
}

export default Loader
